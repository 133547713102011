@keyframes blinkBorder {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: green;
  }
  100% {
    border-color: transparent;
  }
}

.blinking {
  animation: blinkBorder 0.5s ease-in-out infinite;
}

.stopped {
  border-color: none;
}
.border-transparent {
  border: black 1px;
}
