$backgroun_color: black;
$loading_message_color: #6c757d;
$neon_green: #39ff14;
$text_menu: #6c757d;
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
div {
  transition: all 0.5s ease;
}

body {
  background-color: $backgroun_color;
  font-family: Arial, sans-serif;
}
.custom-hr {
  border-top: 2px solid $text_menu;
  width: 80%;
  margin: 5px auto; /* Centering the line */
}

.tiny-badge {
  padding: 0.25em 0.4em; /* Adjust padding to make it smaller */
  font-size: 0.6rem; /* Smaller font size */
  line-height: 1; /* Keep line height small */
  border-radius: 50%; /* Make it a circle */
  min-width: 1rem; /* Ensure it's always circular even with a single digit */
  min-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-pointer {
  cursor: pointer !important;
  transition: all 0.3s ease;
}
.c-pointer:hover {
  transform: scale(1.06);
}
.text-neon {
  color: $neon_green !important;
}
.text-link {
  color: inherit; /* Inherit the color from the parent element */
  text-decoration: none; /* Remove the underline */
}

.text-link:hover,
.text-link:focus {
  color: inherit; /* Ensure color doesn't change on hover or focus */
  text-decoration: none; /* Keep the underline removed on hover or focus */
}
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
}

.loading-message {
  margin-top: 20px;
  color: $loading_message_color;
}
.max-height-75 {
  max-height: 75vh; // Adjust as needed for better responsiveness
}
.max-width-100 {
  max-width: 100%;
}
.top-nav {
  height: 60px;
  padding: 0 10px;
  z-index: 900 !important;
}
.footer-nav {
  height: 60px;
  padding: 0 10px;
  z-index: 899 !important;
}
.sosial {
  margin: 0;
  padding: 0;
}
.border-up {
  border-top: 0.5px solid #cccccc88; /* Example border style */
}
.border-down {
  border-bottom: 0.5px solid #cccccc88; /* Example border style */
}
.music-blink {
  animation: blink 1s infinite; /* Apply blinking animation when playing */
}
// .navbar {
//   z-index: 1030; /* Make sure navbar is on top of other content */
// }

/* Style for the toggle button */
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none !important; /* Remove outline */
  box-shadow: none !important; /* Remove box-shadow */
}

/* Customize the appearance of the toggle button icon */
.btn-menu .bi {
  color: white;
}

/* Style for additional component in the navbar */
.navbar-component {
  color: white;
  font-size: 1rem;
}

/* Customize the appearance of the offcanvas */
.offcanvas-custom {
  background-color: #000; /* Black background for offcanvas */
  height: 100vh;
}

/* Style the offcanvas body */
.offcanvas-body {
  padding: 1rem;
}

/* Ensure offcanvas covers the full height of the screen */
.offcanvas {
  height: 100vh; /* Full viewport height */
}
.navbar-expand-lg .navbar-toggler {
  display: block !important;
}

/* Additional styles to ensure toggle button is properly visible */
.navbar-toggler {
  background: transparent;
  border: none;
}
@media (max-width: 991px) {
  .offcanvas-custom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw; /* Full viewport width */
  }
}
.nav-link.disabled {
  pointer-events: none; /* Prevent clicking */
  opacity: 0.5; /* Visual indication of being disabled */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.nav-link.active {
  font-weight: bold; /* Example style for active link */
  color: $neon_green !important;
}
.nav-link.active .active-span {
  color: $neon_green !important;
}
.nav-link {
  transition: all 0.3s ease, color 0.3s ease; /* Smooth transition */
  transform-origin: left center; /* Scale from the right side */
}

.nav-link:hover:not(.active) {
  color: $neon_green !important;
  transform: scale(1.2); /* Scale up */
  margin-left: 10px;
}
.text-menu {
  color: $text_menu;
}
.offcanvas-close-btn {
  color: $text_menu;
  transition: all 0.3s ease-in-out;
}
.offcanvas-close-btn:hover {
  color: rgba(142, 5, 5, 0.714);
  transform: scale(1.1);
}
.auth-button {
  background-color: $text_menu !important;
  color: white;
  border: none;
  padding: 3px 10px;

  font-size: 0.7em;

  transition: all 0.3s ease-in-out;
}

.auth-button .bi {
  transition: margin-right 0.8s ease-in-out;
}

.auth-button span {
  transition: opacity 0.8s ease-in-out;
}

.auth-button:hover {
  background-color: $text_menu;
  color: $neon_green;
  font-size: 0.72em;
}
.auth-button:disabled {
  background-color: darken(
    $text_menu,
    10%
  ) !important; // Slightly darker for a disabled effect
  color: rgba(255, 255, 255, 0.5); // Lightened text to indicate disabled state
  cursor: not-allowed; // Shows a 'not-allowed' cursor
  opacity: 0.6; // Reduce opacity to visually indicate the button is disabled
  transform: none; // Prevents scaling effect
}

.auth-button:disabled:hover {
  background-color: darken($text_menu, 10%) !important;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.7em; // Prevents the hover effect from changing size
}
/* Dark Theme Modal Styling */
.auth-modal .modal-content {
  background-color: #333;
  border: none;
  border-radius: 10px;
}

.auth-modal .modal-header {
  border-bottom: none;
}

.auth-modal .modal-body {
  color: #ddd;
}

.auth-modal .modal-footer {
  border-top: none;
}

.auth-modal .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.auth-modal .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.auth-modal .btn-primary:hover,
.auth-modal .btn-secondary:hover {
  opacity: 0.85;
}

.auth-modal h4 {
  color: #f8f9fa;
}

.auth-modal .close {
  color: #fff;
}

.copy-icon-wrapper {
  display: inline-block;
}

.copy-icon-wrapper button {
  background: none;
  border: none;
  padding: 0;
}

.copy-icon-wrapper i {
  font-size: 16px; /* Adjust as needed */
}

.profile-btn {
  padding: 4px;
  border: 1px $loading_message_color solid;
  background-color: #6c757d8a;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}
.profile-btn:hover {
  background-color: #6c757dde;
  transform: scale(1.02);
}
.unreaded-badge-color {
  background-color: #ff4500 !important;
  color: white;
}
.unread-border {
  border: 2px solid #ff4500;
  box-shadow: -1px 0px 23px -11px rgba(255, 0, 0, 0.75);
  -webkit-box-shadow: -1px 0px 23px -11px rgba(255, 0, 0, 0.75);
  -moz-box-shadow: -1px 0px 23px -11px rgba(255, 0, 0, 0.75);
}

.custom-input {
  color: white !important; /* Ensures typed text is white */
}

.custom-input::placeholder {
  color: rgba(255, 255, 255, 0.5) !important; /* Light gray placeholder */
  opacity: 1; /* Ensures visibility */
}
.square-row {
  aspect-ratio: 1;
}
.main-page {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content {
  max-height: calc(100vh - (60px + 60px));
}

.video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.main-video {
  max-width: 100%;
  max-height: 70vh;
  object-fit: cover;
  border-radius: 8px;
}
