/* Ensure the parent container takes the full height of the viewport */
.loading-container {
  height: 100vh;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: black; /* Ensure the background covers the full screen */
}

/* Adjustments for the loading text */
.loading-custom {
  font-family: 'Arial Narrow', sans-serif;
  font-weight: 100;
  font-size: 1em;
  color: #39ff14;
  text-align: center;
}

/* Animation for each letter */
.loading-in {
  display: inline-block;
  text-transform: uppercase;
  opacity: 0;
  transform: rotateX(-90deg);
}

/* Drop animation with staggered delays */
.let1 { animation: drop 1.2s ease-in-out infinite; animation-delay: 0.2s; }
.let2 { animation: drop 1.2s ease-in-out infinite; animation-delay: 0.3s; }
.let3 { animation: drop 1.2s ease-in-out infinite; animation-delay: 0.4s; }
.let4 { animation: drop 1.2s ease-in-out infinite; animation-delay: 0.5s; }
.let5 { animation: drop 1.2s ease-in-out infinite; animation-delay: 0.6s; }
.let6 { animation: drop 1.2s ease-in-out infinite; animation-delay: 0.7s; }
.let7 { animation: drop 1.2s ease-in-out infinite; animation-delay: 0.8s; }
.let8 { animation: drop 1.2s ease-in-out infinite; animation-delay: 0.9s; }
.let9 { animation: drop 1.2s ease-in-out infinite; animation-delay: 1s; }
.let10 { animation: drop 1.2s ease-in-out infinite; animation-delay: 1.1s; }

/* Adjusted loading delay positioning for responsiveness */
.loading-delay {
  margin-top: 20px;
  font-size:0.75em;
}

/* Keyframes for the drop animation */
@keyframes drop {
  10% { opacity: 0.5; }
  20% { opacity: 1; transform: rotateX(-360deg); }
  80% { opacity: 1; transform: rotateX(-360deg); }
  90% { opacity: 0.5; }
  100% { opacity: 0; }
}

/* Media query for smaller screens */
@media (max-width: 576px) {
  .loading-custom {
    font-size: 0.85em; /* Reduce font size on small screens */
  }
  .loading-delay {
    font-size: 0.6em; /* Slightly reduce the delay text size */
  }
}

/* Media query for larger screens */
@media (min-width: 1200px) {
  .loading-custom {
    font-size: 1.5em; /* Increase font size on large screens */
  }
  .loading-delay {
    font-size: 0.75em; /* Increase delay text size */
  }
}
